<template>
    <div class="headerLegacy text-center bodyBG">
        <p>{{$t('login.zulu')}} <router-link :to="{name : 'introducingZuluTrade'}">{{$t('login.here')}}</router-link>. {{$t('login.existing')}} <a :href="static_vars.OldWebsiteURL" target="_blank">{{$t('login.here')}}</a></p>
    </div>
    <div class="userSteps_header border-bottom d-flex align-items-center align-items-center justify-content-between">
        <!-- <router-link to="/" class="logo d-block lightTheme"><img src="/assets/images/logo.svg" :alt="$t('login.text1')"
          :title="$t('login.title')" /></router-link>
          <router-link to="/" class="logo darkTheme d-none"><img src="/assets/images/whiteLogo.svg" :alt="$t('login.text1')"
          title="{{$t('login.title')}}" /></router-link> -->
          <a :href="`${static_vars.domainURL}`" class="logo d-block lightTheme"><img src="/assets/images/logo.svg" :alt="$t('login.text1')"
          :title="$t('login.title')" /></a>
          <a :href="`${static_vars.domainURL}`" class="logo darkTheme d-none"><img src="/assets/images/whiteLogo.svg" :alt="$t('login.text1')"
          title="{{$t('login.title')}}" /></a>

        <div class="rightBTN d-flex align-items-center justify-content-end position-relative">
          <a href="javascript:void(0)" class="d-flex align-items-center me-md-4 me-2 medium linkBtn" @click.prevent="openMenu = !openMenu">
            <span class="maskedIcon"></span> <span class="mobHide">{{$t('login.mobile_app')}}</span></a>
              <div class="maxAuto dropdown-menu p-3 text-center" :class="openMenu?'show':''">
                    <p class="mb-0 f-14 line-4">{{$t('login.scan_qr')}}</p>
                    <ul class="mb-0 d-flex align-items-center">
                        <v-lazy-image width="140" class="mx-auto cardborder" src="/assets/images/zuluScan1.svg" :alt="$t('login.text2')" :title="$t('login.text3')" />
                    </ul>
                    <ul class="mb-0 d-flex align-items-center justify-content-center">
                        <li>
                            <a href="https://apps.apple.com/gr/app/zulutrade/id336913058" target="_blank" class="fadebg appStore text-white medium f-14 me-2">
                                <v-lazy-image src="/assets/images/iosIcon.png" :alt="$t('login.text4')" :title="$t('login.text5')" />
                                <span>{{$t('login.ios')}}</span>
                            </a>
                        </li>
                        <li>
                            <a href="https://play.google.com/store/apps/details?id=zulu.trade.app" target="_blank" class="fadebg appStore text-white medium f-14">
                                <v-lazy-image src="/assets/images/androidIcon.png" :alt="$t('login.text6')" :title="$t('login.text7')" />
                                <span>{{$t('login.android')}}</span>
                            </a>
                        </li>
                    </ul>
              </div>
              <a id="theme-toggle" class="d-flex" :title="$t('login.text9')" :class="[{'light':currentTheme=='light'},{'dark':currentTheme=='dark'}]" href="javascript:void(0)" @click="changeTheme()">
                    <vue-feather class="me-1" size="20" type="moon"  v-if="currentTheme=='light'"></vue-feather> 
                    <vue-feather class="me-1" size="20" type="sun" v-if="currentTheme=='dark'"></vue-feather> 
                </a>
        </div>
    </div>
    <section class="blockElement ContentBlock">
        <div class="container">
            <div class="row justify-content-center vhCalc align-items-center">
                <div class="col-12 col-md-8 col-xl-5">
                    <div class="bg-white boxed px-md-5 py-md-4">
                        <h1 class="f-25 bold letter-space-0 mb-4 text-center line-1">{{$t('login.log_in')}}</h1>
                        <div class="d-flex justify-content-center googleSignInButton">
                                <GoogleSignInButton
                                    @success="handleLoginSuccess"
                                    @error="handleLoginError"
                                    size = "medium"
                                ></GoogleSignInButton>
                            </div>
                            <div class="text-center">
                                <p class="or medium text-center my-4">{{$t('login.or')}}</p>
                            </div>
                        <Form class="login-content" @submit="submit">
                            <div class="form-group">
                                <label>{{$t('login.address')}}</label>
                                <Field
                                    class="form-control"
                                    name="email"
                                    autofill="off"
                                    autocomplete="username"
                                    v-model="form.username"
                                    :placeholder="$t('login.email_placeholder')"
                                    rules="required"
                                    />
                                <ErrorMessage class="text-danger" name="email" />
                            </div>
                            <div class="form-group">
                                <label>{{$t('login.password')}}</label>
                                <div class="position-relative">
                                    <Field
                                :type="inputtype"
                                class="form-control"
                                autofill="off"
                                name="password"
                                v-model="form.password"
                                rules="required|min:2"
                                :placeholder="$t('login.password_placeholder')"
                                autocomplete="new-password"
                                />
                                <vue-feather class="position-absolute eye pointer searchIcon searchIcon-auto d-flex" :type="iconType" size="23" @click="switchVisibility"></vue-feather>
                                </div>
                                
                                
                                <ErrorMessage class="text-danger" name="password" />
                                
                                <div class="forgotPassord mt-1">
                                    <a :href="static_vars.domainURL+'register/forgot-password'" class="link f-13">{{$t('login.forgot')}}</a>
                                </div>
                            </div>
                            <div class="form-group">
                                <button type="submit" class="button fillBtn large w-100" href="javascript:void(0)">{{$t('login.button')}}</button>
                            </div>
                            <!--  <div class="text-center form-group">
                                <p class="or f-16">or Sign in Using</p>
                            </div>
                            <div class="text-center form-group">
                                <a class="borderButton" href="javascript:void(0)">
                                    <v-lazy-image width="70" height="20" src="../../../public/assets/images/google-logo.svg" :alt="Logo" :title="Login With Google" />
                                </a>
                            </div> -->
                            
                            <div class="text-center form-group">
                                <p class="mb-0">{{$t('login.member')}} <a :href="static_vars.domainURL+'register/'" class="link f-13">{{$t('login.sign_up')}}</a></p>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="footerSteps d-flex align-items-center">
        <div class="logoFooter d-flex align-items-center">
            <v-lazy-image class="lightTheme" src="/assets/images/footerLogo.png" loading="lazy" width="90" height="18" :alt="$t('login.text8')" :title="$t('login.copy')" />
            <v-lazy-image class="darkTheme d-none" src="/assets/images/white-logo.svg" loading="lazy" width="90" height="18" :alt="$t('login.text8')" :title="$t('login.copy')" />
            <p class="mb-0 ms-2">{{$t('login.year')}} {{ COMMON.newYear() }}</p>
        </div>
        <ul class="footerLinks d-flex align-items-center justify-content-center mb-0">
            <!-- <li v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 38"><a href="/assets/pdf/legal/TOS-EU.pdf" class="ps-0" target="_blank">Terms of service</a> · </li>
            <li v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 1"><a href="/assets/pdf/legal/TOS-EU.pdf" class="ps-0" target="_blank">Terms of service</a> · </li> -->
            <li><router-link to="/terms-of-service" target="_blank">{{$t('login.terms')}}</router-link>· </li>
            <li><router-link to="/privacy-policy" target="_blank">{{$t('login.privacy')}}</router-link>· </li>
            <li><router-link to="/risk-disclaimer" target="_blank">{{$t('login.risk')}}</router-link></li>
        </ul>
    </div>
    <div class="container-fluid">
        <div class="position-fixed advertisingCookies d-flex align-items-center justify-content-between" v-if="showCookiesConsent">
            <div class="cookiesContent">
                <h4>We respect your privacy!</h4>
                <p>We need some cookies for our site to function, these are called “<span class="secondary">essential</span>” cookies.</p>
                <!-- For screen only for SM -->
                <div class="d-block d-sm-none mb-2">
                    <p :class="[{'d-none':!readMoreLess}]">We'd also like to use “<span class="secondary">analytics and performance</span>” cookies and “<span class="secondary">advertising cookies</span>”. You can control our use of these cookies in the consent banner below. Accepting all cookies enables us to enhance your experience further. Please note, some of these may be third-party cookies. You can modify your cookie preferences by clicking the button below. For more information, please see our <a class="secondary underline" href="/cookies-policy"> Cookie Policy</a>.</p>
                    <span class="secondary" @click="readMoreLess = !readMoreLess">{{readMoreLess ? 'Read less' : 'Read more'}}</span>
                </div>
                <!-- For screen's more than SM -->
                <p class="d-none d-sm-block">We'd also like to use “<span class="secondary">analytics and performance</span>” cookies and “<span class="secondary">advertising cookies</span>”. You can control our use of these cookies in the consent banner below. Accepting all cookies enables us to enhance your experience further. Please note, some of these may be third-party cookies. You can modify your cookie preferences by clicking the button below. For more information, please see our <a class="secondary underline" href="/cookies-policy"> Cookie Policy</a>.</p>
            </div>
            <div class="d-flex align-items-center flex-shrink-0 flex-nowrap equalButton">
                <a data-bs-toggle="modal" data-bs-target="#modifyPreferencesModal" class="button border-button zulu_btn rounded px-ms-4 me-3" rel="nofollow" href="javascript:void(0)">Modify Preferences</a>
                <a class="button zulu_btn rounded fillBtn px-ms-4" rel="nofollow" href="javascript:void(0)" @click="setGDPRConsent('accept_all')">Accept All</a>
            </div>
        </div>
    </div>
    <!-- Modal Fot Cookies -->
    <div class="fade modal" id="modifyPreferencesModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <h4 class="mb-3">Why are we using cookies?</h4>
                    <p>We use cookies to help you navigate efficiently and perform certain functions. You will find detailed information about all cookies under each consent category below.</p>
                    <div class="usingCookies">
                        <div class="accordion accordion-flush" id="accordionFlushExample">
                            <div class="accordion-item d-flex align-items-start border-0 mb-4 pb-2">
                                <label class="switch me-3 mt-2 flex-shrink-0 essential">
                                    <input type="checkbox" name="essential" v-model="essentialCokies" disabled>
                                    <span class="slider round"></span>
                                </label>
                                <div class="w-100">
                                    <h2 class="accordion-header" id="flush-headingOne">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                        <p class="mb-0 bold">Essential cookies</p>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body pb-0 pt-2 ps-0"><p class="mb-0">These are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data.</p></div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item d-flex align-items-start border-0 mb-4 pb-2">
                                <label class="switch me-3 mt-2 flex-shrink-0">
                                    <input type="checkbox" name="analytics" v-model="analyticsCookies">
                                    <span class="slider round"></span>
                                </label>
                                <div class="w-100">
                                    <h2 class="accordion-header" id="flush-headingTwo">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                        <p class="mb-0 bold">Analytics</p>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body pb-0 pt-2 ps-0"><p class="mb-0">On this website, we use cookies to measure our audience, analyse website data and improve your experience. You can select here those you allow to stay.</p></div>
                                    </div>
                                </div>
                            </div>
                            <div class="accordion-item d-flex align-items-start border-0 mb-4 pb-2">
                                <label class="switch me-3 mt-2 flex-shrink-0">
                                    <input type="checkbox" name="advertising" v-model="advertisingCookies">
                                    <span class="slider round"></span>
                                </label>
                                <div class="w-100">
                                    <h2 class="accordion-header" id="flush-headingThree">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                        <p class="mb-0 bold">Advertising</p>
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body pb-0 pt-2 ps-0">
                                            <p class="mb-0">On this website, we use cookies to measure our audience, nurture our relationship with you and send you from time to time some quality content and some advertisement. You can select here those you allow to stay.</p>
                                                <label class="switch d-flex align-items-center mt-4 toggleFB flex-shrink-0">
                                                <input type="checkbox" name="facebook" v-model="facebookCookies">
                                                <span class="slider round"></span>
                                                <span class="text bold">Facebook Pixel</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex align-items-center flex-shrink-0 equalButton">
                        <a class="button border-button zulu_btn rounded px-ms-4 me-3" rel="nofollow" href="javascript:void(0)" @click="setGDPRConsent('Reject')">Reject All</a>
                        <a class="button border-button zulu_btn rounded px-ms-4 me-3" rel="nofollow" href="javascript:void(0)" @click="setGDPRConsent('Save')">Save Preferences</a>
                        <a class="button zulu_btn rounded fillBtn px-ms-4" rel="nofollow" href="javascript:void(0)" @click="setGDPRConsent('accept_all')">Accept All</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import { Form, Field, ErrorMessage } from "vee-validate";
    import PriceFeed from '@/store/stomp';
    import moment from "moment"
    import { load } from 'recaptcha-v3'
    import $ from 'jquery'

    export default {
        setup() {
            const store = myStore();
            const Crypto = require("crypto-js");
            return { store,Crypto };//Crypto
        },
        data(){
            return {
                form : {
                  username : '',
                  password : ''
              },
              currentTheme : '', 
              showpassword: false,
              openMenu: false,
              iconType: "eye-off",
              inputtype: "password",
              captchaToken : '',
              essentialCokies : true,
              analyticsCookies : false,
              advertisingCookies : false,
              facebookCookies : false,
              showCookiesConsent : false,
              readMoreLess: false
             };
        },
        components: {
            Form,
            Field,
            ErrorMessage,
          },
        methods:{
            handleLoginSuccess(response){
                if(response.credential){
                    let formData = {};
                    formData['tokenType'] = 'GOOGLE'
                    formData['grant_type'] = 'password';
                    formData['token'] = response.credential;
                    if(this.captchaToken){
                        formData['captcha'] = {};
                        formData['captcha']['response'] = this.captchaToken
                    }
                    this.store.register(formData,true,this);
                }
            },
            CretioFunc(){
                try{
                    // window.gtag('event', 'email_verify');
                  if(Object.keys(this.store.flavordata).length && this.store.flavordata.userCountry && Object.keys(this.store.flavordata.userCountry).length && this.store.flavordata.userCountry.isoCode){
                        for(var key in this.static_vars.cretioCountryJson){
                            if(key == this.store.flavordata.userCountry.isoCode){
                                var s = document.createElement("script");
                                s.type = "text/javascript";
                                s.async = true;
                                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${this.static_vars.cretioCountryJson[key]}`;
                                $("head").append(s);
                                window.criteo_q = window.criteo_q || [];
                                 var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                                window .criteo_q.push(
                                 { event: "setaccount", account: this.static_vars.cretioCountryJson[key]}, // You should never update this line
                                 { event: "setSiteType", type: deviceType},
                                 { event: "setemail", email: this.Crypto.SHA256('1').toString() , hash_method: "sha256" },
                                { event: "viewBasket", user_segment : 1, item: [
                                   {id : (Math.random()*10000).toString(), price:1, quality : 1}
                                ]}
                                );
                            }
                        }
                    }
                }catch(e){
                  console.log('cretio ex',e)
                }
            },
            CretioGoogleFunc(){
                try{
                    window.gtag('event', 'test_verify');
                    window.fbq('trackCustom', 'email_verify', {'email' : this.store.customerDetail?.email,'value' : '20','country' : this.store.flavordata?.userCountry?.name},{'eventID' : `${this.store.customerDetail?.id}_email_verify`});
                    if(Object.keys(this.store.flavordata).length && this.store.flavordata.userCountry && Object.keys(this.store.flavordata.userCountry).length && this.store.flavordata.userCountry.isoCode){
                        for(var key in this.static_vars.cretioCountryJson){
                            if(key == this.store.flavordata.userCountry.isoCode){
                                var s = document.createElement("script");
                                s.type = "text/javascript";
                                s.async = true;
                                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${this.static_vars.cretioCountryJson[key]}`;
                                $("head").append(s);
                                window.criteo_q = window.criteo_q || [];
                                 var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                                window .criteo_q.push(
                                 { event: "setaccount", account: this.static_vars.cretioCountryJson[key]}, // You should never update this line
                                 { event: "setSiteType", type: deviceType},
                                 { event: "setemail", email: this.Crypto.SHA256('1').toString() , hash_method: "sha256" }, 
                                  //{ event: "setEmail", email: "1"  },
                                    { event: "viewBasket", user_segment : 1, item: [
                                         {id : (Math.random()*10000).toString(), price:1, quality : 1}
                                    ]},
                                    { event: "trackTransaction", id: (Math.random() * 50000), item: [
                                        {id :  Math.ceil(Math.random()*10300 * Math.random() * 50000), price:1, quality : 1}
                                    ]}
                                );
                            }
                        }
                    }
                }catch(e){
                  console.log('cretio ex',e)
                }
            },
            handleLoginError(){
                console.error("Login failed");
            },
            loginWithGoogle(data){
                let formData = {};
                formData['grant_type'] = 'password'
                formData['googleToken'] = data
                formData['restrictInactiveCounties'] = true
                this.store.login(formData,true,'basic',this)

            },
            changeTheme(){
                if(this.currentTheme=='light'){
                    this.currentTheme = 'dark'
                } else {
                    this.currentTheme = 'light'
                }
            },
            submit(){
                let formData = {};
                formData['grant_type'] = 'password'
                formData['password'] = this.form.password
                formData['username'] = this.form.username
                formData['restrictInactiveCounties'] = true
                this.store.login(formData,true,'basic',this)
            },
            validEmail(){
                let regexp =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                return regexp.test(this.form.username.toLowerCase())
            },
            switchVisibility() {
              this.showpassword = !this.showpassword;
              if (this.showpassword) {
                this.inputtype = "text";
                this.iconType = "eye";
              } else {
                this.inputtype = "password";
                this.iconType = "eye-off";
              }
            },
            UserDetail(){
                this.store.getUserDetail({},true,this,'').then(() => {
                    // if(Object.keys(response).length){
                    //     if(window.localStorage.storekey){
                    //         const store = JSON.parse(window.localStorage.storekey)
                    //         if(store.customerDetail){
                    //           store.customerDetail = this.store.userDetail
                    //           window.localStorage.setItem("storekey",  JSON.stringify(store))
                    //            if(this.static_vars.getCookie('zuluUserDetail')){
                    //               this.static_vars.delete_cookie('zuluUserDetail')
                    //             }
                    //         }
                    //     }else{
                    //         this.static_vars.setCookie('zuluUserDetail',JSON.stringify(response),'100d')
                    //     }
                    // }
                })
            },
            allCategoryPrices(){
                let formData = {
                    "from": moment().subtract(2, 'week').format('YYYY-MM-DD'),
                    "to": moment().format('YYYY-MM-DD'),
                    "limit": 10,
                    "page": 1,
                }

                let forexPayload = {
                    "categories": [
                        1
                    ],
                    ...formData
                }
                let commoditiesPayload = {
                    "categories": [
                        3
                    ],
                    ...formData
                }
                let cryptoPayload = {
                    "categories": [
                        4
                    ],
                    ...formData
                }
                let indicesPayload = {
                    "categories": [
                        7
                    ],
                    ...formData
                }
                let stocksPayload = {
                    "categories": [
                        8
                    ],
                    ...formData
                }
                this.store.getAllCurrencyCategories(forexPayload,false)
                this.store.getAllCurrencyCategories(commoditiesPayload,false)
                this.store.getAllCurrencyCategories(cryptoPayload,false)
                this.store.getAllCurrencyCategories(indicesPayload,false)
                this.store.getAllCurrencyCategories(stocksPayload,false)
            },
            callSocket(){
                PriceFeed.getInstance().deactivate();
            },
            getWatchList(){
                this.store.getWatchList({},true,'1')
                this.store.getWatchList({},true,'180')
            },
            getCustomerDetail(){
                this.store.callCustomerDetail({},false).then((response) =>{
                    if(Object.keys(response).length && response.accounts && Object.keys(response.accounts).length){
                        this.store.$patch({'dashboardTradesTab' : 'open'})
                      this.store.getProviderOpenTrades({'page': '0','size':'10'},true,this.store.userSelectedAccount.brokerAccountId);
                      this.getDashboardData(this.store.userSelectedAccount.zuluAccountId);
                      this.getCopyLeaders(this.store.userSelectedAccount.zuluAccountId);
                    }
                })
            },
            getCopyLeaders(ID){
                this.store.getCopyStats({},false,ID)
                this.store.GetCopyTraders({},false)
            },
            getDashboardData(ID){
                this.store.getUserDashboardData({},true,ID)
            },
            SetToken(response){
                if(Object.keys(response).length && response.access_token){
                    localStorage.setItem('zulutoken', response.access_token);
                    if(response.refresh_token){
                        localStorage.setItem('zulurefreshToken', response.refresh_token);
                    }
                }
            },
            hideModal(){
                let modal = document.getElementById('modifyPreferencesModal');
                modal.style.display = "none";
                document.body.classList.remove('modal-open');
                const backdrop = document.querySelector('.modal-backdrop');
                if (backdrop) {
                    backdrop.remove();
                }
            },
            gtag(){window.dataLayer.push(arguments)},
            setGDPRConsent(type){
                try{
                    const consentJson = {
                        'ad_storage': 'denied',
                        'ad_user_data': 'denied',
                        'ad_personalization': 'denied',
                        'analytics_storage': 'denied',
                        'functionality_storage' : 'granted'
                    };
                    if (localStorage.getItem("consent") === null) {
                        if (type === 'accept_all') {
                            Object.keys(consentJson).forEach(key => {
                                consentJson[key] = 'granted';
                            });
                            window.fbq('consent', 'grant');
                        } else if (type === 'Reject') {
                            this.analyticsCookies = false;
                            this.advertisingCookies = false;
                            window.fbq('consent', 'revoke');
                        } else {
                            if (this.analyticsCookies) {
                                consentJson['analytics_storage'] = 'granted';
                            }
                            if (this.advertisingCookies) {
                                consentJson['ad_storage'] = 'granted';
                                consentJson['ad_user_data'] = 'granted';
                                consentJson['ad_personalization'] = 'granted';
                            }
                            if(this.facebookCookies){
                                window.fbq('consent', 'grant');
                            }
                        }
                        this.gtag('consent', 'update', consentJson);
                        var gtagScript = document.createElement('script');
                        gtagScript.async = true;
                        gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.gtmId}`;
                        var firstScript = document.getElementsByTagName('script')[0];
                        firstScript.parentNode.insertBefore(gtagScript,firstScript);
                        localStorage.setItem("consent", JSON.stringify(consentJson));
                        this.showCookiesConsent = false
                        this.hideModal()
                    }
                }catch(e){
                    console.log('e',e)
                }
            }
        },
        mounted() {
            load(`${this.static_vars.recaptchaKey}`,{useRecaptchaNet: true}).then((recaptcha) => {
              recaptcha.execute('login').then((token) => {
                  // console.log(token) // Will print the token
                  this.captchaToken = token
                })
            })
            if(localStorage.getItem('theme') === null){
                localStorage.setItem('theme', 'light');
            }
            var toggle = document.getElementById("theme-toggle");
            var storedTheme = localStorage.getItem('theme') || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
            if (storedTheme) {
                document.documentElement.setAttribute('data-theme', storedTheme)
            }
            if(toggle){
                toggle.onclick = function() {
                    var currentTheme = document.documentElement.getAttribute("data-theme");
                    var targetTheme = "light";
                    if (currentTheme === "light") {
                        targetTheme = "dark";
                    }
                    document.documentElement.setAttribute('data-theme', targetTheme)
                    localStorage.setItem('theme', targetTheme);
                };
            }
            this.currentTheme = localStorage.getItem('theme')
        },
        created(){
            if (localStorage.getItem("consent") === null) {
                const script = document.createElement('script');
                script.innerHTML = `
                    window.dataLayer = window.dataLayer || [];
                    function gtag() { dataLayer.push(arguments); }
                    gtag('js', new Date());
                    gtag('consent', 'default', {
                    'ad_user_data': 'denied',
                    'ad_personalization': 'denied',
                    'ad_storage': 'denied',
                    'analytics_storage': 'denied',
                    'functionality_storage': 'granted'
                    });
                    gtag('js', new Date());
                    gtag('config', '${this.gtmId}');
                `;
                document.head.appendChild(script);
                this.showCookiesConsent = true
            }else{
                this.showCookiesConsent = false
            }
        }
    }
</script>
